<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 3/7</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Votre chauffage</span>
                    </template>
                </SurveyHeader>

                <!-- heatType field -->
                <div class="survey-question">
                    Quel est votre type de chauffage principal&nbsp;?
                </div>
                <SquareList :items="heatTypes" :selected="[heatType.value]" @click-item="addHeatType"/>

                <div class="text-error mb-4" v-if="heatType.error">{{ heatType.error }}</div>


                <!-- heatSources field -->
                <div class="survey-question">
                    Quels sont vos systèmes de chauffage&nbsp;?
                    <div class="mt-1 font-size-14 line-height-1-1">Sélectionnez tous les systèmes présents chez vous.</div>
                </div>
                <SquareList :items="sources" :selected="[...heatSources.value]" @click-item="updateHeatSource"/>
                    
                <div class="text-error mb-4" v-if="heatSources.error">{{ heatSources.error }}</div>


                <!-- otherClarification field -->
                <div v-if="heatSources.value.has('other')">
                    <div class="survey-question">
                        Vous avez coché "Autres / Je ne sais pas", pouvez-vous préciser&nbsp;?
                    </div>

                    <div class="control">
                        <textarea
                            class="textarea input-border"
                            :class="{ 'dangerous': otherClarification.error }"
                            v-model="otherClarification.value"
                            type="text"
                            placeholder=""
                            name="otherClarification"
                            maxlength=2048
                        ></textarea>
                    </div>

                    <div v-if="otherClarification.error" class="text-error mb-4">{{ otherClarification.error }}</div>
                </div>


                <!-- mainHeatSource field -->
                <div v-if="heatSources.value.size > 1">
                    <div class="survey-question">
                        Parmi les systèmes sélectionnés, quel est le principal&nbsp;?
                    </div>

                    <div v-for="source in sources" :key="`main-${source.slug}`">
                        <RadioButton
                            v-if="heatSources.value.has(source.slug)"
                            :label="source.label"
                            :checked="source.checked"
                            @click="defineMainHeatSource(source.slug)"
                        />
                    </div>

                    <div v-if="mainHeatSource.error" class="text-error mb-4">
                        {{ mainHeatSource.error }}
                    </div>
                </div>


                <!-- frequencies field -->
                <div v-if="heatSources.value.size > 1 && mainHeatSource.value != null">
                    <div v-for="source in sources" :key="`frequency-${source.slug}`">
                        <div v-if="heatSources.value.has(source.slug) && source.frequency != 'main' && source.slug != 'other'">
                            <div class="survey-question">
                                {{ source.labelLong }}&nbsp;:
                            </div>

                            <RadioButton
                                label="Occasionnellement"
                                :checked="source.frequency == 'occasionally'"
                                @click="updateFrequency(source.slug, 'occasionally')"
                            />
                            <RadioButton
                                label="Régulièrement"
                                :checked="source.frequency == 'regularly'"
                                @click="updateFrequency(source.slug, 'regularly')"
                            />

                            <div v-if="heatFrequencies.error && source.frequency == null" class="text-error mb-4">
                                {{ heatFrequencies.error }}
                            </div>
                        </div>
                    </div>
                </div>


                <!-- heatPumpType field -->
                <div v-if="heatSources.value.has('heat_pump')">
                    <div class="survey-question">
                        Quel type de pompe à chaleur avez-vous&nbsp;?
                    </div>

                    <RadioButton
                        v-for="el, index in heatPumpTypeOptions"
                        :label="el.label"
                        :checked="el.checked"
                        @click="changeRadioButton(index, 'heatPumpType', heatPumpType, heatPumpTypeOptions)"
                    />

                    <div class="text-error mb-4" v-if="heatPumpType.error">{{ heatPumpType.error }}</div>
                </div>


                <!-- heatPumpConditioning field -->
                <div v-if="heatSources.value.has('heat_pump') && (heatPumpType.value == 'air_air' || heatPumpType.value == 'air_water' )">
                    <div class="survey-question">
                        Utilisez-vous votre pompe à chaleur pour la climatisation&nbsp;?
                    </div>

                    <RadioButton
                        v-for="el, index in heatPumpConditioningOptions"
                        :label="el.label"
                        :checked="el.checked"
                        @click="changeRadioButton(index, 'heatPumpConditioning', heatPumpConditioning, heatPumpConditioningOptions)"
                    />

                    <div class="text-error mb-4" v-if="heatPumpConditioning.error">{{ heatPumpConditioning.error }}</div>
                </div>
            </div>
            

            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>

                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { ref, reactive, onMounted } from 'vue'
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import SquareList from '@/components/survey/SquareList.vue';
import RadioButton from '@/components/survey/RadioButton.vue';
import { processSurveyError } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const heatType = ref({ error: null, value: null })
const heatSources = reactive({ error: null, value: new Set() })
const otherClarification = ref({ error: null, value: null })
const mainHeatSource = ref({ error: null, value: null })
const heatFrequencies = ref({ error: null })
const heatPumpType = ref({ error: null, value: null })
const heatPumpConditioning = ref({ error: null, value: null })

const heatTypes = [
    {
        slug: "collective",
        label: 'Chauffage collectif'
    },
    {
        slug: "individual",
        label: 'Chauffage individuel'
    },
    {
        slug: "unknown",
        label: 'Je ne sais pas'
    }
]

const sources = reactive([
    {
        slug: "elec",
        label: 'Radiateur électrique',
        labelLong: 'Le radiateur électrique est utilisé',
        checked: false,
        frequency: null
    },
    {
        slug: "heat_pump",
        label: 'Pompe à chaleur',
        labelLong: 'La pompe à chaleur est utilisée',
        checked: false,
        frequency: null
    },
    {
        slug: "gas",
        label: 'Chaudière gaz',
        labelLong: 'La chaudière gaz est utilisée',
        checked: false,
        frequency: null
    },
    {
        slug: "wood",
        label: 'Bois',
        labelLong: 'Le bois est utilisé',
        checked: false,
        frequency: null
    },
    {
        slug: "fuel_oil",
        label: 'Chaudière fioul',
        labelLong: 'La chaudière fioul est utilisée',
        checked: false,
        frequency: null
    },
    {
        slug: "other",
        label: 'Autres / Je&nbsp;ne&nbsp;sais&nbsp;pas',
        labelLong: 'Les autres sont utilisés',
        checked: false,
        frequency: null
    },
])

const heatPumpTypeOptions = reactive([
    {
        label: "Air / air",
        slug: "air_air",
        checked: false
    },
    {
        label: "Air / eau",
        slug: "air_water",
        checked: false
    },
    {
        label: "Géothermique",
        slug: "geothermal",
        checked: false
    },
    {
        label: "Je ne sais pas",
        slug: "unknown",
        checked: false
    }
])

const heatPumpConditioningOptions = reactive([
    {
        label: "Occasionnellement, en cas de vague de chaleur",
        slug: "occasionally",
        checked: false
    },
    {
        label: "Régulièrement pendant l’été",
        slug: "regularly",
        checked: false
    },
    {
        label: "Jamais",
        slug: "never",
        checked: false
    }
])

const initOptions = (field, optionsObject) => {
    optionsObject.forEach(el => {
        if(el.slug == field.value) el.checked = true
    })
}

if (userStore.account.survey_data) {
    heatType.value.value = userStore.account.survey_data.heat_type || null
    if(userStore.account.survey_data.heat_sources && userStore.account.survey_data.heat_sources.length > 0) {
        userStore.account.survey_data.heat_sources.forEach(el => heatSources.value.add(el))
    }
    otherClarification.value.value = userStore.account.survey_data.heat_sources_other_clarification || null
    heatPumpType.value.value = userStore.account.survey_data.heat_pump_type || null
    heatPumpConditioning.value.value = userStore.account.survey_data.heat_pump_conditioning || null
    initOptions(heatPumpType.value, heatPumpTypeOptions)
    initOptions(heatPumpConditioning.value, heatPumpConditioningOptions)
    if(userStore.account.survey_data.heat_frequencies) {
        sources.forEach(el => {
            if(Object.keys(userStore.account.survey_data.heat_frequencies).includes(el.slug)) {
                el.frequency = userStore.account.survey_data.heat_frequencies[el.slug]
                if(userStore.account.survey_data.heat_frequencies[el.slug] == 'main') {
                    mainHeatSource.value.value = el.slug
                    el.checked = true
                }
            }
        })
    }
}

const resetOptions = (field, optionsObject) => {
    field.value = null
    optionsObject.forEach((el, i) => {
        el.checked = false
    })
}

const changeRadioButton = (index, what, field, optionsObject) => {
    optionsObject.forEach((el, i) => {
        if(i == index) {
            el.checked = true
            field.value = el.slug
        }
        else el.checked = false
        if(what == 'heatPumpType' && (el.slug == 'geothermal' || el.slug == 'unknown' )) {
            resetOptions(heatPumpConditioning.value, heatPumpConditioningOptions)
        }
    })
}

const updateFrequency = (slug, frequency) => {
    sources.forEach(el => {
        if(el.slug == slug) {
            el.frequency = frequency
        }
    })
}

const defineMainHeatSource = (slug) => {
    sources.forEach(el => {
        if(el.frequency == 'main') {
            el.frequency = null
            el.checked = false
        }
        if(el.slug == slug) {
            el.frequency = 'main'
            el.checked = true
            mainHeatSource.value.value = slug
        }
    })
}

const addHeatType = (type) => {
    heatType.value.value = type
}

const updateHeatSource = (slug) => {
    if(heatSources.value.has(slug)) {
        heatSources.value.delete(slug)
        if(slug == "other") otherClarification.value.value = null
        if(slug == "heat_pump") {
            resetOptions(heatPumpType.value, heatPumpTypeOptions)
            resetOptions(heatPumpConditioning.value, heatPumpConditioningOptions)
        }
        if(mainHeatSource.value.value == slug) {
            mainHeatSource.value.value = null
        }
        sources.forEach(el => {
            if(slug == el.slug || mainHeatSource.value.value == null){
                el.frequency = null
                el.checked = false
                
            }
        })
    }
    else heatSources.value.add(slug)

    if(heatSources.value.size <= 1) {
        mainHeatSource.value.value = null
        sources.forEach(el => {
            el.frequency = null
            el.checked = false
        })
    }
}

const done = async() => {
    const frequencies = sources.reduce(
        (acc, el) => {
            if(el.frequency != null) {
                acc[el.slug] = el.frequency
            }
            return acc
        },
        {}
    )

    let payload = {
        'step3_done': true,
        'heat_type': heatType.value.value,
        'heat_sources': [...heatSources.value],
        'heat_sources_other_clarification': otherClarification.value.value,
        'heat_frequencies': frequencies,
        'heat_pump_type': heatPumpType.value.value,
        'heat_pump_conditioning': heatPumpConditioning.value.value
    }

    let hasError = false;

    if (heatType.value.value == null) {
        heatType.value.error = "Merci de sélectionner un type de chauffage."
        hasError = true
    } else {
        heatType.value.error = null
    }
    if (heatSources.value.size == 0) {
        heatSources.error = "Merci de sélectionner au moins un système de chauffage."
        hasError = true
    } else {
        heatSources.error = null
    }
    if (heatSources.value.size > 1 && mainHeatSource.value.value == null) {
        mainHeatSource.value.error = "Merci de sélectionner votre moyen de chauffage principal."
        hasError = true
    } else {
        mainHeatSource.value.error = null
    }
    let frequenciesLength = heatSources.value.has('other') ? Object.keys(frequencies).length + 1 : Object.keys(frequencies).length
    if (heatSources.value.size > 1 && mainHeatSource.value.value != null && frequencies != undefined && frequenciesLength != heatSources.value.size) {
        heatFrequencies.value.error = "Merci de préciser votre usage."
        hasError = true
    } else {
        heatFrequencies.value.error = null
    }
    if (heatSources.value.has("other") && (otherClarification.value.value == null || otherClarification.value.value.trim() == "")) {
        otherClarification.value.error = "Merci de préciser votre réponse."
        hasError = true
    } else {
        otherClarification.value.error = null
    }
    if (heatSources.value.has("heat_pump") && heatPumpType.value.value == null) {
        heatPumpType.value.error = "Merci de sélectionner un type de pompe."
        hasError = true
    } else {
        heatPumpType.value.error = null
    }
    if (heatSources.value.has("heat_pump") && (heatPumpType.value.value == 'air_air' || heatPumpType.value.value == 'air_water') && heatPumpConditioning.value.value == null) {
        heatPumpConditioning.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        heatPumpConditioning.value.error = null
    }

    console.log('done', payload)

    if (!hasError) {
        try {
            isLoading.value = true
            let resp = await API.pushSurveyData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0]})
                await userStore.update_profile()
                if(Object.keys(userStore.account.survey_data).includes('step3_done')){
                    router.push({ name: "Survey4" })
                } else {
                    router.push({ name: "Survey3" })
                }
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "heat_type": heatType.value.error || '',
            "heat_sources": heatSources.error || '',
            "other_clarification": otherClarification.value.error || '',
            "main_heat_source": mainHeatSource.value.error || '',
            "heat_frequencies": heatFrequencies.value.error || '',
            "heat_pump_type": heatPumpType.value.error || '',
            "heat_pump_conditioning": heatPumpConditioning.value.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}

onMounted(() => {
    if(!Object.keys(userStore.account.survey_data).includes('step2_done')){
        router.push({ name: "Survey2" })
    }
})
</script>

<style scoped>

</style>
