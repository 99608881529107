<template>
    <div class="my-container" style="">
        <div>
            <div class="bg-blue-5 has-text-centered" style="padding: 50px 20px 10px 20px;">
                <img src="/src/assets/img/waiting-screen-gas.svg" />

                <div class="font-size-20 text-blue-2 has-text-weight-bold line-height-1-2 mb-3">
                    Vous y êtes presque ! Nous avons besoin d’avoir accès à vos données de gaz.
                </div>

                <div class="font-size-14 has-text-primary line-height-1-2">
                    Le chauffage est votre plus gros poste de dépense énergétique. L’accès à vos données de consommation de gaz nous permet de vous fournir des conseils personnalisés.
                </div>
            </div>
            <div style="background: linear-gradient(var(--winter-blue-5) 50px, white 50px); padding: 10px;">
                <div class="flexcol radius-10 bg-white has-text-primary line-height-1-2" style="padding: 20px;">
                    <div class="has-text-weight-bold mb-3 has-text-centered">
                        Voici les prochaines étapes :
                    </div>
                    <div class="chip-text-container">
                        <div class="chip">1</div>
                        <div class="has-text-weight-semibold font-size-14">
                            <span>Notre partenaire homeys vous demande </span>
                            <span class="text-blue-2">votre numéro de compteur de gaz </span>
                            <span>et </span>
                            <span class="text-blue-2">votre date de début de contrat</span>
                            <span>.</span>
                        </div>
                    </div>
                    <div class="chip-text-container">
                        <div class="chip">2</div>
                        <div class="font-size-14">
                            <span class="has-text-weight-semibold">Vous recevez un email de GRDF </span>
                            <span>pour donner accès à homeys à vos données de gaz.</span>
                        </div>
                    </div>
                    <div class="chip-text-container">
                        <div class="chip">3</div>
                        <div class="font-size-14">
                            <span class="has-text-weight-semibold">Vous acceptez </span>
                            <span>(enfin on espère...).</span>
                        </div>
                    </div>
                    <div class="chip-text-container">
                        <div class="chip">4</div>
                        <div class="font-size-14">
                            <span class="has-text-weight-semibold">C'est tout bon ! </span>
                            <span>Vous profitez de conseils personnalisés pour diminuer votre consommation énergétique.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div style="margin: 20px;">
            <div
                class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold"
                style="width: 100%; margin-bottom: 36px;"
                @click="next">
                C'est parti !
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, onMounted } from 'vue';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const error = ref(false)

const next = () => {
    userStore.trackEvent('go_to_homeys', {'page': route.path, 'origine': userStore.externalParamsArray[0]})
    router.push({ name: 'EmbeddedHomeys' })
}

onMounted(async() => {
    try{
        // To officially close the survey and begin calls the API to retrieve the energy datas,
        // call this endpoint:
        console.log("ENDING")
        userStore.endingSurvey()
    } catch (e) {
        console.log("ERROR", e)
        error.value = true
    }
    userStore.trackEvent('view_homeys', {'page': route.path, 'origine': userStore.externalParamsArray[0]})
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;
}

.chip-text-container {
    display: flex;
    flex-direction: row;
    color: var(--winter-blue-1);
    margin-bottom: 14px;
}

.chip {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 50%;
    background-color: var(--winter-blue-1);
    color: white;
    font-weight: bold;
    align-content: center;
    margin-right: 10px;
    text-align: center;
}
</style>