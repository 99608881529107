<template>
    <div class="my-container has-text-centered">
        <div class="video-card">
            <img class="trees" src="/src/assets/img/waiting-screen-trees.svg"/>
            <div style="position: absolute; bottom: 36px;">
                <div style="width: auto; display: inline-block;">
                    <video class="data-video" autoplay loop muted>
                        <source src="/src/assets/video/waiting-screen.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <img class="stars left" src="/src/assets/img/waiting-screen-stars-left.svg"/>
            <img class="stars right" src="/src/assets/img/waiting-screen-stars-right.svg"/>
        </div>
        
        <div class="flexcol text-card" style="padding: 20px; justify-content: space-between; flex-grow: 1; z-index: 4;">
            <div class="pt-2">
                <div class="font-size-25 has-text-weight-bold has-text-primary mb-3 mt-5">
                    Merci pour vos réponses !
                </div>
                <div class="flexcol font-size-14 has-text-weight-medium has-text-primary line-height-1-2" style="margin-bottom: 30px;">
                    Nous analysons vos données de consommation 
                    énergétique...
                </div>
                <div class="font-size-20 has-text-weight-bold text-blue-2 line-height-1-2 mb-5">
                    Apprenez en plus sur votre consommation d'énergie en attendant votre diagnostic.
                </div>
            </div>
            <div
                class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold"
                style="width: 100%; margin-bottom: 36px;"
                @click="next">
                Suivant
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, onMounted } from 'vue';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const dataAreReady = ref(false)
const error = ref(false)

const next = () => {
    userStore.trackEvent('click_next_step', {'page': route.path, 'origine': userStore.externalParamsArray[0]})
    router.push({ name: 'WaitingScreen2' })
}

onMounted(async() => {
    // In the case of users are come back from renovation typeform
    // and they are gas consumption
    // redirect them to gas page
    if (userStore.hasGas) {
        router.push({name: 'WaitingScreenGas'})
    } else {
        try{
            // To officially close the survey and begin calls the API to retrieve the energy datas,
            // call this endpoint:
            console.log("ENDING")
            userStore.endingSurvey()
        } catch (e) {
            console.log("ERROR", e)
            error.value = true
        }
    }
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.video-card {
    max-height: 45vh;
    min-height: 45vh;
    position: relative;
    overflow-x: hidden;
}

.trees {
    z-index: 1;
    position: absolute;
    min-width: 120% !important;
    bottom: -16px;
    transform: translateX(-54%);
}

.data-video {
    width: 80%;
    height: auto;
    position: relative;
    left: 15px;
}

.text-card {
    background-color: var(--winter-blue-5);
}

.stars {
    position: absolute;

    &.left {
        bottom: 30%;
        left: 16%;
    }
    &.right {
        top: 13%;
        right: 10%;
    }
}

</style>