import axios from 'axios';
import API from '@/services/api';
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useAlertsStore } from './alert'

const ecowatt_showing_values = [2, 3]

export const useUserStore = defineStore('user', {
    state: () => ({
        isAuthenticated: false,
        account: null,
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        selectedBanAddress: null,
        prmFound: null,
        externalParams: {},
    }),
    getters: {
        consumedEnergy: (state) => {
            let gasTotal = 0
            let consoTotalGasInEuros = 0
            if (Object.keys(state.account.gas_diagnostic_indicators).length > 0) {
                gasTotal = state.account.gas_diagnostic_indicators.conso_total_avant_telechargement
                consoTotalGasInEuros = gasTotal * state.account.gas_price
            }
            return Math.floor((state.account.diagnostic_indicators.conso_total_avant_telechargement * state.account.elec_price) + consoTotalGasInEuros)
        },
        isThermostatProcess: (state) => {
            let ret = false
            if (state.account != null && state.account.origin == 'thermostat-connecte') {
                ret = true
            }
            return ret
        },
        externalParamsArray: (state) => {
            let origin = null
            let utm = null
            let partner = {}
            let externalParams = {...state.externalParams}
            if (externalParams != null) {
                if (Object.keys(externalParams).includes('origine')) {
                    origin = externalParams.origine
                    if (origin == "MaPetitePlanète") origin = "MaPetitePlanete"
                    delete externalParams.origine
                }
                if (Object.keys(externalParams).includes('partner_team_id')) {
                    partner['partner_team_id'] = externalParams.partner_team_id
                    delete externalParams.partner_team_id
                }
                if (Object.keys(externalParams).includes('partner_user_id')) {
                    partner['partner_user_id'] = externalParams.partner_user_id
                    delete externalParams.partner_user_id
                }
                utm = Object.keys(externalParams).length > 0 ? externalParams : null
            }
            if (Object.keys(partner).length == 0) partner = null
            return [origin, utm, partner]
        },
        hasPartnerCheckbox: (state) => {
            let ret = false
            if (state.externalParams != null) {
                if (state.externalParams['partner_user_id'] != undefined && state.externalParams['partner_user_id'] != '') {
                    ret = true
                }
            }
            return ret
        },
        hasGas: (state) => {
            let ret = false
            if ((state.account.survey_data.heat_type == 'individual' && 
                state.account.survey_data.heat_sources.includes('gas')) || 
                state.account.survey_data.hot_water_source == 'gas'
            ) {
                ret = true
            }
            return ret
        }
    },
    actions: {
        "setExternalParams": function (data) {
            this.externalParams = data
        },
        "set_account": function (data) {
            if (!data.survey_data) {
                data.survey_data = {}
            }
            this.account = data
        },
        "login": async function (user, pass) {
            let resp = await API.login(user, pass)
            // console.log('login', resp.data)
            this.update_tokens(resp.data.access, resp.data.refresh)
            this.isAuthenticated = true
            resp = await API.myProfile()
            // console.log(resp.data)
            this.account = resp.data.account
            this.$router.push({ name: "HomeRouter" })
        },
        "update_profile": async function () {
            let resp = await API.myProfile()
            const alertsStore = useAlertsStore()
            // console.log(resp)
            this.isAuthenticated = true
            if (resp.data.account.origin != null || resp.data.account.utm != null) {
                this.setExternalParams({'origine': resp.data.account.origin, ...resp.data.account.utm })
            }

            let accountId = resp.data.account.id
            if (resp.data.account.incomplete_experience_on_app){
                if (resp.data.account.incomplete_experience_on_app.user_with_too_few_data) {
                    alertsStore.addAlert('fewData', accountId)
                } else {
                    alertsStore.removeAlert('fewData')
                }
                if (resp.data.account.incomplete_experience_on_app.user_without_electric_heating) {
                    // A user without electric heating has few data so display just one alert
                    alertsStore.removeAlert('fewData')
                    alertsStore.addAlert('noHeat', accountId)
                } else {
                    alertsStore.removeAlert('noHeat')
                }
            }

            //- Alerts for gas process
            // We ckeck first if user has filled the survey
            if (resp.data.account.survey_data &&
                Object.keys(resp.data.account.survey_data).includes('heat_sources') &&
                Object.keys(resp.data.account.survey_data).includes('hot_water_source') &&
                Object.keys(resp.data.account.survey_data).includes('cooking_devices')
            ) {
                // If user has declared gas usage
                if (resp.data.account.survey_data.heat_sources.includes('gas') || 
                    resp.data.account.survey_data.hot_water_source == 'gas' ||
                    resp.data.account.survey_data.cooking_devices.burner > 0 ||
                    resp.data.account.survey_data.cooking_devices.gas_cooker > 0
                ) {
                    console.log("update_profile user declared gas usage")
                    // If user has no gas data yet
                    if (!resp.data.account.has_gas_data) {
                        console.log("update_profile user has no gas data yet")
                        // If user did homeys process without error
                        if (resp.data.account.homeys_status == 'ok') {
                            alertsStore.removeAlert('homeysError')
                            console.log("Homeys status OK")
                            // If user didn't click on grantingGrdf alert button 
                            // to say that he granted grdf
                            if (resp.data.account.granting_grdf == null) {
                                if (resp.data.account.homeys_signup_time != null) {
                                    let quarterHourInSeconds = 15 * 60
                                    let difference = Math.abs((Date.now() / 1000) - resp.data.account.homeys_signup_time)
                                    // change the alert after 15 minutes
                                    if (difference < quarterHourInSeconds) {
                                        console.log("checkEmailGrdf ")
                                        alertsStore.addAlert('checkEmailGrdf', accountId)
                                    } else {
                                        console.log("grantingGrdf ")
                                        alertsStore.addAlert('grantingGrdf', accountId)
                                    }
                                }
                            }
                            // If user clicked on grantingGrdf alert button
                            else if (resp.data.account.granting_grdf) {
                                console.log("grantingGrdfTrue ")
                                alertsStore.removeAlert('grantingGrdf')
                                alertsStore.removeAlert('checkEmailGrdf')
                                alertsStore.addAlert('grantingGrdfTrue', accountId)
                            }
                        }
                        else if (resp.data.account.homeys_status == 'ko') {
                            console.log("Homeys status KO")
                            alertsStore.addAlert('homeysError', accountId)
                        }
                    }
                    else {
                        alertsStore.removeAlert('grantingGrdfTrue')
                        alertsStore.removeAlert('grantingGrdf')
                        alertsStore.removeAlert('checkEmailGrdf')
                        alertsStore.removeAlert('homeysError')
                    }
                }
            }
            this.account = resp.data.account
        },
        "update_tokens": function (access, refresh) {
            localStorage.setItem('access_token', access)
            localStorage.setItem('refresh_token', refresh)
            this.access_token = access
            this.refresh_token = refresh
        },
        "do_refresh_token": async function () {
            try {
                let response = await axios.post(`${BACKEND_ROOT}/login/token/refresh/`, { refresh: localStorage.getItem('refresh_token') })
                this.update_tokens(response.data.access, localStorage.getItem('refresh_token'))
            } catch (error) {
                console.log("refresh_token failed")
                // context.dispatch("logout")
            }
        },
        "logout": async function () {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            this.access_token = null
            this.refresh_token = null
            this.isAuthenticated = false
            this.$router.push({ name: "SignUp" })
        },
        "want_free_thermostat": function () {
            if (this.account.survey_data.thermostat || this.account.survey_data.heat_sources.includes('elec') && this.account.survey_data.heat_sources.length > 2 ) {
                // TODO renvoie vers une page Webflow de non éligibilité
            } else {
                // Send to eligibility survey
                let email = this.account.user.username
                let firstName = this.account.enedis_first_name
                let lastName = this.account.enedis_last_name
                // let surface = this.account.survey_data.surface
                let address = this.account.enedis_address
                let city = this.account.enedis_city_name
                let zipcode = this.account.enedis_zipcode
                // let homeOwner = this.account.survey_data.home_owner ? 'proprietaire' : 'locataire'
                // let department = this.account.enedis_city_code.slice(0, 2)
                // let type = this.account.survey_data.type == 'house' ? 'maison individuelle' : 'appartement'
                let source = this.account.utm != null && Object.keys(this.account.utm).includes('utm_source') ? this.account.utm['utm_source'] : 'null'
                let prm = this.account.enedis_prm
                // let data = `email=${email}&prenom=${firstName}&nom=${lastName}&surface=${surface}&adresse=${address}&ville=${city}&codepostal=${zipcode}&proprietaire=${homeOwner}&departement=${department}&type=${type}`
                // window.location.href = "https://winter-energies.typeform.com/to/iZlKdCU9#" + data;
                // changement du lien: ticket 502 https://gitlab.crossdata.tech/c0211/hamster/backend/-/issues/502
                let data = `email=${email}&prenom=${firstName}&nom=${lastName}&adresse=${address}&codepostal=${zipcode}&ville=${city}&source=${source}&prm=${prm}`
                window.location.href = "https://winter-energies.typeform.com/to/gsWXTOxb#" + data;
            }
        },
        "check_thermostat_eligibility": function () {
            // Send to eligibility survey
            let email = this.account.user.username
            let firstName = this.account.enedis_first_name
            let lastName = this.account.enedis_last_name
            let address = this.account.enedis_address
            let zipcode = this.account.enedis_zipcode
            let city = this.account.enedis_city_name
            let source = this.account.utm != null && Object.keys(this.account.utm).includes('utm_source') ? this.account.utm['utm_source'] : 'null'
            let prm = this.account.enedis_prm
            let data = `email=${email}&prenom=${firstName}&nom=${lastName}&adresse=${address}&codepostal=${zipcode}&ville=${city}&source=${source}&prm=${prm}`
            window.location.href = "https://winter-energies.typeform.com/to/gsWXTOxb#" + data;
        },
        "get_eco_watt_daily_status": async function () {
            let resp = await API.getEcoWattDailyStatus()
            let isEcoWattDay = false
            let statusNumber = -1
            if (resp.status == 200 ) {
                statusNumber = resp.data
                // if today signal is in ecowatt_showing_values, this is an ecowatt day
                if (ecowatt_showing_values.includes(resp.data)) isEcoWattDay = true;
            }
            return {"isEcoWattDay": isEcoWattDay, "statusNumber": statusNumber }
        },
        "hasValidToken": async function () {
            let ret = false
            try {
                let response = await axios.post(`${BACKEND_ROOT}/login/token/verify/`, { token: localStorage.getItem('refresh_token') })
                if (response.status == 200) ret = true 
                console.log("VERIFY TOKEN", response.status)
            } catch (error) {
                console.log("verify_token failed")
            }
            return ret
        },
        "trackEvent": function (event_name, payload) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': event_name,
                ...payload
            });
            console.log("dataLayer", window.dataLayer)
        },
        "endingSurvey": function () {
            console.log("ENDING SURVEY STORE", this)
            API.endingSurveyData(this.account.id).then((resp) => {
                if (resp.status == 200) {
                    console.log("!!! 200 !!!")
                    this.trackEvent('finish_onboarding', {'origine': this.externalParamsArray[0]})
                    this.update_profile()
                    console.log("AFTER ENDING SURVEY check survey_done", this.account.survey_done)
                }
            })
        }
    }
})
