<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 5/7</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Quels appareils utilisez-vous pour cuire ou réchauffer vos aliments&nbsp;?</span>
                    </template>
                </SurveyHeader>

                <!-- cookingDevices field -->
                <SquareList :items="sources" :selected="[...cookingDevices.value]" :display-value="true" @click-item="updateCookingDevices"/>

                <div class="text-error mb-4" v-if="cookingDevices.error">{{ cookingDevices.error }}</div>
            </div>


            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>
                
                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, reactive, onMounted } from 'vue';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import SquareList from '@/components/survey/SquareList.vue';
import { processSurveyError } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const cookingDevices = reactive({ error: null, value: new Set()})

const sources = reactive([
    {
        value: 0,
        maximum: 1,
        label: "Four électrique encastrable",
        slug: "integrated_electric_oven"
    },
    {
        value: 0,
        maximum: 1,
        label: "Four gaz encastrable",
        slug: "integrated_gas_oven"
    },
    {
        value: 0,
        maximum: 1,
        label: "Four électrique posé sur le plan de travail",
        slug: "electric_mini_oven"
    },
    {
        value: 0,
        maximum: 1,
        label: "Plaques de cuisson électriques",
        slug: "hotplates"
    },
    {
        value: 0,
        maximum: 1,
        label: "Plaques de cuisson gaz",
        slug: "burner"
    },
    {
        value: 0,
        maximum: 1,
        label: "Cuisinière (four&nbsp;et&nbsp;plaques) au gaz",
        slug: "gas_cooker"
    },
    {
        value: 0,
        maximum: 1,
        label: "Cuisinière (four&nbsp;et&nbsp;plaques) électrique",
        slug: "electric_cooker"
    },
    {
        value: 0,
        maximum: 1,
        label: "Micro-ondes",
        slug: "microwave"
    },
    {
        value: 0,
        maximum: 1,
        label: "Autres / Je&nbsp;ne&nbsp;sais&nbsp;pas",
        slug: "other"
    }
])

if (userStore.account.survey_data.cooking_devices != undefined) {
    sources.forEach(el => {
        if(Object.keys(userStore.account.survey_data.cooking_devices).includes(el.slug)) {
            el.value = userStore.account.survey_data.cooking_devices[el.slug]
            if(el.value > 0) cookingDevices.value.add(el.slug)
        }
    })
}

const updateCookingDevices = (slug) => {
    sources.forEach(el => {
        if(el.slug == slug) {
            if(el.value < el.maximum) {
                el.value += 1
                cookingDevices.value.add(slug)
            } else {
                el.value = 0
                cookingDevices.value.delete(slug)
            }
        }
    })
}

const done = async() => {
    const devices = sources.reduce(
        (acc, el) => {
            acc[el.slug] = el.value
            return acc
        },
        {}
    )

    let payload = {
        'step5_done': true,
        'cooking_devices': devices,
    }

    console.log('done', payload)

    try {
        isLoading.value = true
        let resp = await API.pushSurveyData(userStore.account.id, payload)
        if (resp.status == 200) {
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0]})
            await userStore.update_profile()
            if(Object.keys(userStore.account.survey_data).includes('step5_done')){
                    router.push({ name: "Survey6" })
                } else {
                    router.push({ name: "Survey5" })
                }
        }
    } catch (e) {
        isLoading.value = false
        error.value = processSurveyError(e)
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': error.value})
    }
    isLoading.value = false
}

onMounted(() => {
    if(!Object.keys(userStore.account.survey_data).includes('step4_done')){
        router.push({ name: "Survey4" })
    }
})
</script>

<style scoped>

</style>
